import {config} from "../config";

class GameSocketService{
    gameWs

    constructor() {
        this.gameWs = new WebSocket(config.SERVER_SOCKET);
        this.gameWs.onopen = function () {
            console.log('подключился');
        };
    }

    sendGameCommand(command, data = null){
        this._sendAction(command, data)
    }
    _sendAction(actionName, data=null){
        const message= {action: actionName, data}
        this.gameWs.send(JSON.stringify(message))
    }
}

const gameSocketService = new GameSocketService()
export default gameSocketService
