import React, {useState} from 'react';
import s from './control-game.module.css'
import gameSocketService from "../../services/game-socket.service";

const ControlGameComponent = () => {
    const [time,setTime] = useState(null)
    const [locations,setLocations] = useState(null)
    const [gameInfo, setGameInfo] = useState(null)
    const [routs,setRouts] = useState(null)
    const [isRedactTaks, setIsRedactTask] = useState(false)
    const [moderation, setModeration] = useState([])
    const [textMessage, setTextMessage] = useState('')
    const [timeRound, setTimeRound] = useState('')
    const [timeWait, setTimeWait] = useState('')
    const [timeTransit, setTimeTransit] = useState('')
    const [score, setScore] = useState([])
    const gameSocket = gameSocketService.gameWs
    const sendMessageAllUser = ()=>{
        gameSocketService.sendGameCommand('message', textMessage)
    }
    gameSocket.onmessage = (message) => {
        const messageData = JSON.parse(message.data)
        setGameInfo({isStart: messageData.data.isStart, gameStep: messageData.data.gameStep, isFinish:  messageData.data.isFinish, round:  messageData.data.round})
        console.log(messageData)
        setTime(messageData?.data?.time)
        setRouts(messageData?.data?.routs)
        setLocations(messageData?.data?.locations)
        setTimeWait(messageData?.data?.timeWait)
        setTimeRound(messageData?.data?.timeRound)
        setTimeTransit(messageData?.data?.timeTransit)
        const modArr = []
        const scoreTeams = []
        for (const rout of messageData.data.routs) {
            let score = 0
            for (const report of rout.roundReport) {
                let add = 0
                if  ( !report.timeRoundStart && report.timeRoundStart !== 0){
                    console.log('нет старта')

                }   else{
                    if ( report.timeRoundFinish)
                        add =  report.timeRoundFinish -  report.timeRoundStart
                }

                for (const answer of report.answers) {
                    if (answer.isModeration){
                        modArr.push({answer, chatId: rout.chatId, teamName: rout.teamName, roundNumber: report.roundNumber, titleLocation: report.location.title,task: report.location.tasks.find(r=>r.id === answer.id)})
                    }
                    if (answer.isModeration || !answer.isTrue){
                        add = 0
                    }
                }
                score += add!==0?(601* rout.roundReport.length-add):0
            }
            scoreTeams.push(score)
        }
        setScore(scoreTeams)
        setModeration(modArr)
    }
    gameSocket.onclose = ()=>{
        console.log('disconnect')
        setTimeout(()=>{
            window.location.reload();
        },3_000)
    }

    return (
        <div className={s.wrapper}>
            Телеграм игра
            {/*{time?*/}
                <div>
                    <div onClick={()=> {
                        if (window.confirm("Отправить запрос на проверку готовности")){
                            gameSocketService.sendGameCommand('askReadyToPlay')
                            window.alert("Запрос отправлен")
                        }
                    }} className={s.btn}>Запрос готовности</div>
                    <div onClick={()=> {
                        if (window.confirm("Старт игры")) {
                            gameSocketService.sendGameCommand('start')
                        }
                    }} className={s.btn}>Запуск игры</div>
                    <div onClick={()=> {
                        if (window.confirm("Остановить игру")) {
                            gameSocketService.sendGameCommand('stop')
                        }
                    }} className={s.btn}>Стоп</div>
                    <div onClick={()=> {
                        if (window.confirm("Сброс игры")) {
                            gameSocketService.sendGameCommand('refresh')
                        }
                    }} className={s.btn}>Сброс</div>
                </div>
            {gameInfo?
            <div>
                {time?<div> Время: {Math.floor(time/60)}:{time-Math.floor(time/60)*60}</div>:<></>}
                <div>{gameInfo.isStart?'Игра запущена': "Игра не запущена"}</div>
                {/*{gameInfo.round?<div>Раунд {gameInfo.round}</div>: ""}*/}
                {/*{gameInfo.isFinish?<div>'Игра завершена'</div>: ""}*/}
                {/*<div>{gameInfo.gameStep??"-"}</div>*/}
            </div>:<></>
            }

            {routs?
            <table className={s.table}>
                <tbody>
                <tr>
                    <th>команда</th>
                    <th>готовность</th>
                    <th>локация</th>
                    <th>состояние</th>
                    <th>раунд</th>
                    <th>время</th>
                    <th>счет</th>
                    <th>пройденных уровней</th>
                    <th>победных уровней</th>
                    <th>всего ответов</th>
                    <th>принятых ответов</th>
                    <th>всего времени</th>
                </tr>
                {routs.map((rout, i)=>{
                    let teamTime = '-'
                    const winRound = rout.roundReport?.filter(t=>t.isTrue)?.length??0
                    const allRound = rout.roundReport?.filter(t=>t.answers.length).length??0
                    let allTime = 0
                    let answer =  0
                    let trueAnswer = 0
                    rout?.roundReport?.forEach(r=> {
                        if (r.isTrue){
                            if (r.timeRoundFinish){
                                allTime += (r.timeRoundFinish - r.timeRoundStart)
                            } else {
                                allTime += (r.timeRoundFinish - r.timeRoundStart)
                            }

                        }
                        r?.answers?.forEach(a => {
                            answer++
                            if (a.isTrue) {
                                trueAnswer++
                            }
                        })
                    })
                    allTime = allTime + ( (allRound - winRound) * 600)
                    if (rout.timeRoundStart>=0){
                        let lastTime = timeRound*60- ( time - rout.timeRoundStart)
                        if (rout.currentGameStep !== 'раунд')
                            lastTime = timeWait*60 -  ( time    - rout.timeRoundStart)
                        teamTime = `${Math.floor(lastTime/60)}:${lastTime-Math.floor(lastTime/60)*60}`
                    }
                    const scoreCurrentTeam = (Number(trueAnswer)*100) + Number(score[i])
                    console.log( (trueAnswer*100), score?score[i]:0)
               return (
                   <tr key={`rout_line${i}`}>
                    <td>{rout.teamName??"-"}</td>
                    <td>{rout.isReady?"готов":"-"}</td>
                    <td>{rout.currentRound?.location?.title??"-"}</td>
                    <td>{rout.currentGameStep}</td>
                    <td>{rout.currentRoundNumber+1}</td>
                    <td>{teamTime}</td>
                     <td>{scoreCurrentTeam}</td>
                     <td>{allRound}</td>
                       <td>{winRound}</td>
                     <td>{answer}</td>
                     <td>{trueAnswer}</td>
                     <td>{allTime}</td>
                  </tr>
               )
            })}
                </tbody>
            </table>:<></>
            }
            <div className={s.block}>
                <textarea className={s.input} rows={3} onChange={e=>setTextMessage(e.target.value)} value={textMessage}/>
                <div className={s.btn} onClick={()=>
                {
                    sendMessageAllUser()
                    window.alert("сообщение отправлено")
                }}>Отправить сообщение</div>
            </div>
            <div className={s.btn} onClick={()=>setIsRedactTask(!isRedactTaks)}>Редактировать задания</div>
            {locations && isRedactTaks?
                <div>
                    <h2>Редактор времени</h2>
                    <div>
                        <div>время раунда</div>
                        <input type="number" value={timeRound} onChange={e=>{
                            gameSocketService.sendGameCommand('setTimeRound', {value: e.target.value})}
                        }/>
                        <div>время для фото</div>
                        <input type="number" value={timeWait} onChange={e=>{
                            gameSocketService.sendGameCommand('setTimeWait', {value: e.target.value})}
                        }/>
                        {/*<div>время перехода</div>*/}
                        {/*<input type="number" value={timeTransit} onChange={e=>{*/}
                        {/*    gameSocketService.sendGameCommand('setTimeTransit', {value: e.target.value})}*/}
                        {/*}/>*/}
                    </div>
                    <h2>Редактор маршрута</h2>
                    {routs?.map?
                        <table>
                            <tbody>
                            {routs.map((rout,i)=>{
                                if (rout.rout.map)
                                    return(
                                        <tr key={`row_team_${i}`}>
                                            <td>{rout.teamName}</td>
                                            {rout.rout.map((routLocation,index)=>{
                                                return(
                                                    <td key={`rout_map_${index}`}><input type="text" value={routLocation.locationId} onChange={e=>{
                                                        gameSocketService.sendGameCommand('updateMap', {teamName:rout.teamName, indexLocation:index, value: e.target.value})}
                                                    }/></td>
                                                )
                                            })}

                                        </tr>
                                    )})}

                            </tbody>
                        </table>:<></>
                    }
                    <h2>Редактор заданий</h2>
                    <table className={s.table}>
                        <tbody>
                        {locations.map((loc,i)=>{
                            return (<tr className={s.tr} key={`row_map${i}`}>
                                <td><input type="text" value={loc.title} onChange={(e)=>{
                                    gameSocketService.sendGameCommand('setTitle', {id:loc.id, value: e.target.value})}
                                }/></td>
                                <td><textarea rows={5} type="text" value={loc.description} onChange={(e)=>{
                                    gameSocketService.sendGameCommand('setDescriptionTask', {id:loc.id, value: e.target.value})}
                                }/></td>
                                <td>{loc?.tasks?.map((t,index)=>{
                                    return(
                                        <div key={"loctasks"+index}>
                                            {t.id}.
                                            <input type="text" value={t.text} onChange={(e)=>{
                                                gameSocketService.sendGameCommand('setTextTask', {locationId:loc.id, taskId:t.id, value: e.target.value})}
                                            }/>
                                            <select value={t.type} onChange={e=>   gameSocketService.sendGameCommand('setTypeTask', {locationId:loc.id, taskId:t.id, value: e.target.value})}>
                                                <option value="text">текст</option>
                                                <option value="photo">фото</option>
                                            </select>
                                            {t.type === 'text'?
                                            <div>
                                                <input type="text" onChange={e=> gameSocketService.sendGameCommand('setTrueAnswerTask', {locationId:loc.id, taskId:t.id, value: e.target.value})} value={t.trueAnswer}/>
                                            </div>:<></>
                                            }
                                            <div className={s.btn} onClick={()=>gameSocketService.sendGameCommand('deleteTask', {locationId:loc.id, taskId:t.id})}>удалить</div>
                                        </div>
                                    )
                                })}</td>
                                <td> <div className={s.btn} onClick={()=>gameSocketService.sendGameCommand('addTask', {locationId:loc.id})}>Добавить</div></td>
                            </tr>)
                        })}
                        </tbody>
                    </table>

                </div>:<></>
            }
            {moderation?
            <div>
                <h2>Модерация</h2>
                {moderation.map((task, i)=>{
                    console.log(task)
                    return (<div key={'mod_'+i}>
                        {task.teamName} / {task.titleLocation} / {task.task.text}
                        <div></div>
                        <img className={s.imgMod} src={task.answer.pathUrl} alt=""/>
                        <div></div>
                        <div className={s.btn} onClick={()=>{gameSocketService.sendGameCommand('moderation', {...task, isComplete: true})}}>Подтвердить</div>
                        <div className={s.btn} onClick={()=>{gameSocketService.sendGameCommand('moderation', {...task, isComplete: false})}}>Отменить</div>
                    </div>)
                })}
            </div>:<></>
            }
        </div>
    );
};

export default ControlGameComponent;