import logo from './logo.svg';
import './App.css';
import ControlGameComponent from "./pages/control-game/control-game.component";

function App() {
  return (
    <div className="App">
      <ControlGameComponent />
    </div>
  );
}

export default App;
